import React from 'react'
import styles from './index.module.scss'
import Logo from '../../assets/img/logo.png'

const Index = () => {
    return (
        <div className={styles.navbar}>
            <a href='/'><img src={Logo} className={styles.logo} alt='Логотип'/></a>
            <div className={styles.info}>
                <span className={styles.infoTitle}>Бизнес-ланч</span>
                <span className={styles.time}>12:00 - 15:00</span>
            </div>
        </div>
    )
}

export default Index