import React from 'react'
import styles from './index.module.scss'
import { PiBowlFoodLight } from "react-icons/pi";
import { FaMinus, FaPlus } from "react-icons/fa";

const Index = ({data = [], request, handleGetRequest}) => {
    return ( <> {
        data?.[0]
                ?.categories
                .map((item) => {
                    return (
                        <div key={item.id} className={styles.menu_row}>
                            <div className={styles.menu_row_wrapper}>
                                {item
                                    .menu
                                    .map((item_) => {
                                        return (
                                            <div
                                                key={item_.id}
                                                className={styles.menuBlock}
                                                style={{
                                                width: `${item.menu.length > 4 ? `${((1680 - item.menu.length*2*32)/item.menu.length)}px` : '350px'}`,
                                                backgroundImage: `url(${item_.image
                                                    ?.[0]
                                                        ?.Url})`
                                            }}>
                                                {item_.image
                                                    ?.length === 0
                                                        ? <i className={styles.foodicon}><PiBowlFoodLight/></i>
                                                        : ''}
                                                <div
                                                    style={{
                                                    display: request
                                                        .filter(item => item.id === item_.id)
                                                        .length > 0
                                                        ? 'flex'
                                                        : '',
                                                    boxShadow: request
                                                        .filter(item => item.id === item_.id)
                                                        .length > 0
                                                        ? ' 0 0 0 10px rgb(191, 243, 206)'
                                                        : ''
                                                }}
                                                    className={styles.menu_popup}>
                                                    <span
                                                        onClick={() => handleGetRequest(item_, 'remove')}
                                                        className={styles.count_minus}><FaMinus/></span>
                                                    <span className={styles.count}>{request
                                                            .filter(item => item.id === item_.id)
                                                            .length}</span>
                                                    <span
                                                        onClick={() => handleGetRequest(item_, 'add')}
                                                        className={styles.count_plus}><FaPlus/></span>
                                                </div>
                                                <div className={styles.menu_text}>
                                                    <p className={styles.menu_title}>{item_.title_ru}</p>
                                                    <p className={styles.menu_description}>{item_.description_ru}</p>
                                                </div>
                                            </div>
                                        )

                                    })
}
                            </div>
                        </div>
                    )
                })
    } </>
  )
}

export default Index